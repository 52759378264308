<template>
    <table :style='{"width":"100%", "font-size":"7pt", "border-collapse": "collapse", "line-height": "normal"}'>
        <thead>
            <tr :style='{...estilos.tablaBorde, ...{"text-align":"center", "font-weight": "400"}}'>
                <td
                    width="34%"
                    :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                >
                    Responsable
                </td>

                <td
                    :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    width="33%"
                >
                    Jefe de servicio
                </td>

                <td
                    :style='{...estilos.tablaBorde, ...{"background-color": "#D3D3D3"}}'
                    width="33%"
                >
                    Supervisor
                </td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td :style='{...estilos.tablaBorde, ...{"text-align":"center"}}'>
                    <div v-if="datos">
                        <br/>
                        <br/>
                        {{datos.nombre}} ({{datos.username}})<br/>
                        {{datos.especialidad}} ({{datos.cedula}})<br/>
                        C.U.R.P.: {{datos.curp}}
                    </div>
                </td>
                <td :style='estilos.tablaBorde'>

                </td>
                <td :style='estilos.tablaBorde'>

                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { printEnfe } from '@/mixins/enfermeria/printEnfeMixin.js'
export default {
    mixins: [printEnfe],

}
</script>

<style>

</style>